.userGrid {
    max-width: 500px;
}

.userProfileGrid {
    margin-top: 20px;
}

.userDataRow {
    display: flex;
    flex-direction: column;
    justify-content: center;

    text-align: center;
}

.userSettingsDeviceActiveToggleDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.userSettingsNotificationDeviceOsDiv {
    margin-right: 15px;
}


.userSettingsDeviceDiv {
    flex: 1;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.userSettingsDeviceActiveToggleLabel {
    font-size: xx-small !important;
    color: gray;
}

.userDataEmailParagraph {
    text-align: center;
    color: gray;
}

.userPayedVersionInfoRow {
    display: flex;
    flex-direction: column;
    justify-content: center;

    text-align: center;

    margin-bottom: 20px;
}

.userLogoutButtonRow {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userLogoutButton {
    text-transform: none;
}