.freeTrialPopoverGrid {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.freeTrialPopoverGridRow {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.freeTrialPopoverTitleParagraph {
    font-size: x-large;
    text-align: center;
    font-weight: bold;
}

.freeTrialPopoverRowParagraph {
    text-align: center;
}

.freeTrialPopoverConfirmButton {
    text-transform: none;
}

.featureManagerPayDiv {
    z-index: 1000;
    height: 100%;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
}

.featureManagerPayInnerDiv {
    flex: 1;
    
    width: 60%;
    max-width: 500px;
    
    max-height: fit-content;

    background-color: ghostwhite;
    border-radius: 10px;
    overflow: hidden;

    flex-direction: column;
    justify-content: space-between;

    padding: 10px;
}


@media (prefers-color-scheme: dark) {
    .featureManagerPayInnerDiv {
        background-color: dimgray;
    }
}

.featureManagerPayTextDiv {
    flex: 4;
    line-height: 35px;
}

.featureManagerPayTitle {
    font-size: x-large;
    text-align: center;
    margin: 0;
    margin-top: 10px;
}
.featureManagerPaySubtitle{
    font-size: 16px;
    margin: 0;
    margin-top: 30px;
}

.featureManagerPayFigureParagraph {
    text-align: center;
    font-size: xx-large;
    font-weight: bold;
}

.featureManagerPayButton {
    min-height: 40px;
    display: flex;
    justify-content: center;
    text-transform: none;
    font-size: medium;
    --background: var(--ion-color-primary);
}


/* LEAVE IN-APP BROWSER POPUP */
.featureManagerLeaveInAppBrowserDiv {
    z-index: 1100;
    height: 100%;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.9);

    display: flex;
    justify-content: center;
    align-items: center;
}

.featureManagerLeaveInAppBrowserInnerDiv {
    flex: 1;
    
    width: 60%;
    max-width: 300px;
    
    max-height: 300px;

    background-color: ghostwhite;
    border-radius: 10px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 10px;
}
@media only screen and (max-width: 480px) {
    .featureManagerPayInnerDiv {    
        width: 60%;
        max-width: 85vw;
        
        max-height: fit-content;
    }
    .featureManagerPayTitle {
        font-size: 20px;
        text-align: left;
    }
    .featureManagerPaySubtitle{
        font-size: 14px;
        margin-top: 20px;
    }
    
    .featureManagerPayFigureParagraph {
        font-size: xx-large;
    }
    
    .featureManagerPayButton {
        font-size: 15px;
    }
    .featureManagerPayTextDiv {
        flex: 4;
        line-height: 25px;
        font-size: 16px;
    }
}

@media (prefers-color-scheme: dark) {
    .featureManagerLeaveInAppBrowserInnerDiv {
        background-color: dimgray;
    }
}

.featureManagerLeaveInAppBrowserTopFingerParagraph {
    position: absolute;
    top: 0;
    right: 5px;

    font-size: xx-large;

    animation: featureManagerMoveTopFingerUpDown 1s linear infinite;
}

.featureManagerLeaveInAppBrowserBottomFingerParagraph {
    position: absolute;
    bottom: 0;
    right: 18px;

    font-size: xx-large;

    animation: featureManagerMoveBottomFingerUpDown 1s linear infinite;
}

@keyframes featureManagerMoveTopFingerUpDown {
    0%, 100% {
      top: 0;
    }
    50% {
      top: 10px;
    }
}

@keyframes featureManagerMoveBottomFingerUpDown {
    0%, 100% {
      bottom: 0;
    }
    50% {
      bottom: 10px;
    }
}