.getStartedMainGrid {
	max-width: 500px;
	height: 80vh;

	display: flex;
	flex-direction: column;
}

.getStartedTextDiv {
	flex: 1;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	padding-left: 10px;
	padding-right: 10px;
}

.getStartedTextInnerDiv {
	text-align: center;
}

.getStartedInputsDiv {
	flex: 1;

	padding-left: 10px;
	padding-right: 10px;

	display: flex;
	flex-direction: column;
	justify-content: center;
}

.getStartedInputField {
    --padding-start: 15px;
    --padding-end: 15px;

    border: 1px solid gray;
    border-radius: 20px;
    overflow: hidden;
}

.getStartedEmailInputField {
	margin-bottom: 20px;
}

.getStartedLegalTermsDiv {
	margin-top: 20px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.getStartedLegalTermsParagraph {
	margin-left: 10px;
	color: gray;
	font-size: small;
}

.getStartedButtonsDiv {
	flex: 1;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	padding-left: 10px;
	padding-right: 10px;
}

.getStartedButton {
	text-transform: none;
}

.getStartedResetPasswordButton {
	text-transform: none;
}
