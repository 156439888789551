.paymentProcessingMainGrid {
    max-width: 500px;
}

.paymentProcessingLoadingDiv {
    margin-top: 50px;
    padding: 10px;
}

.paymentProcessingLoadingParagraph {
    text-align: center;
    font-size: larger;
}

.paymentProcessingProcessingDiv {
    margin-top: 50px;
    padding: 10px;
}

.paymentProcessingProcessingParagraph {
    text-align: center;
    font-size: larger;
}

.paymentProcessingCanceledDiv {
    padding: 10px;
    text-align: center;
}

.paymentProcessingCanceledEmoji {
    font-size: 100px;
    margin: 0;
    padding: 0;
    margin-top: 20px;
}

.paymentProcessingCanceledParagraph {
    font-size: larger;
}

.paymentProcessingCanceledBackToPaymentButton {
    margin-top: 50px;
    padding: 10px;
    font-size: large;

    border: 1px solid var(--ion-color-primary);

    border-radius: 10px;
    overflow: hidden;

    cursor: pointer;
}

.paymentProcessingCanceledSurveyButton {
    padding: 10px;
    font-size: large;

    background-color: var(--ion-color-primary);
    color: white;
    border-radius: 10px;
    overflow: hidden;

    cursor: pointer;
}