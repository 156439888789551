.purchaseMainGrid {
    max-width: 500px;
    padding: 10px;

    display: flex;
    flex-direction: column;
}

.purchasePricingDiv {
    text-align: center;
    font-size: x-large;

    margin-bottom: 20px;
}

.purchaseCtaTitleText {
    margin-top: 100px;
    text-align: center;
}

.purchaseButton {
    margin-top: 30px;
    margin-bottom: 10px;
    text-transform: none;
}

.purchaseDiscountCard {
    margin-top: 25px;
    margin-bottom: 15px;

    --background: #98fb9840;
}

@media (prefers-color-scheme: dark) {
    .purchaseDiscountCard {
        --background: #98fb98;
    }
}

.purchaseDiscountDiv {
    color: black !important;
    font-weight: normal;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.purchaseDiscountEmojiParagraph {
    font-size: x-large !important;
    margin: 0;
    padding: 0;
    margin-right: 10px !important;
    margin-left: 10px !important;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
}

.purchaseDiscountTextParagraph {
    font-size: normal !important;
}

.purchaseButtonsAndTermsDiv {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.purchaseRestorePurchasesDiv {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: small;
    color: gray;
    cursor: pointer;
}

.purchaseTermsDiv {
    padding-left: 10px;
    padding-right: 10px;
    font-size: x-small;
    color: gray;
    text-align: center;

    margin-top: 10px;
    margin-bottom: 20px;
}

.purchaseTermsDiv > p > a {
    color: gray;
}