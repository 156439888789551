.countingBasket {
    width: 800px;
    height: 500px;
}

.draggableImage {
    width: 10vw;
    height: 10vw;

    display: flex;
    justify-content: center;

    /* border: 1px solid black;
    border-radius: 15px; */

    margin: 15px;
}

.cardDraggableImage {
    width: 10vw;
    height: 10vw;
}

.countingGameMovableElement {
    width: 10vw;
    height: 10vw;

    display: flex;
    justify-content: center;

    /* border: 1px solid black;
    border-radius: 15px; */

    margin: 15px;

    z-index: 1;
}

.matchingCardsMovableElementsDiv {
    display: grid;
    justify-items: center;
    margin-left: 10px;
    margin-right: 10px;
}

.draggableDiv {
    /* width: 10vw;
    height: 10vw; */

    display: flex;
    justify-content: center;

    /* border: 1px solid black; */
    border-radius: 15px;

    margin: 25px;

    z-index: 1;
}

@media only screen and (max-width: 480px) {
    .countingBasket {
        width: 90%;
        height: 50%;
    }

    .matchingCardsMovableElementsDiv:first-child:nth-last-child(2)>.draggableDiv>.cardCardDiv>.cardDraggableImage {
        width: 40vw;
        height: 40vw;
    }

    .matchingCardsMovableElementsDiv:first-child:nth-last-child(3)>.draggableDiv>.cardCardDiv>.cardDraggableImage {
        width: 35vw;
        height: 35vw;
    }

    .matchingCardsMovableElementsDiv:first-child:nth-last-child(4)>.draggableDiv>.cardCardDiv>.cardDraggableImage {
        width: 30vw;
        height: 30vw;
    }

    .matchingCardsMovableElementsDiv:first-child:nth-last-child(5)>.draggableDiv>.cardCardDiv>.cardDraggableImage {
        width: 25vw;
        height: 25vw;
    }

    .matchingCardsMovableElementsDiv:first-child:nth-last-child(6)>.draggableDiv>.cardCardDiv>.cardDraggableImage {
        width: 20vw;
        height: 20vw;
    }

    .cardDraggableImage {
        width: 20vw;
        height: 20vw;
    }

    .draggableDiv {
        margin: 5px;
    }

    .countingGameMovableElement {
        width: 25vw;
        height: 25vw;
    }
}

@media (prefers-color-scheme: dark) {
    .countingGameMovableElement {
        /* background-color: #ffffff; */
    }

    .countingGameMovableElement {
        /* border: 1px solid whitesmoke; */
    }
}