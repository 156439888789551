.dataCollectionHomeMainGrid {
    width: 100%;
    max-width: 700px;
    padding: 10px;
}

.dataCollectionHomeNoSessionsDiv {
    margin-top: 40vh;

    color: gray;
    text-align: center;
    font-style: italic;

    cursor: pointer;
}

.dataCollectionHomeSessionDiv {
    border-bottom: 1px solid gray;
}

.dataCollectionHomeSessionGameName {
    margin-bottom: 5px;
}

.dataCollectionHomeSessionDate {
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    color: gray;
    font-size: small;
}