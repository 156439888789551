.settingsGrid {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 500px;
}

.settingsRow {
    flex: 1;
}

.settingsFilterSelect {
    min-width: 97% !important;
    padding-left: 15px;
}

.settingsCard {
    flex: 1;
}

.settingsLock {
    font-size: 80px;
    z-index: 100;
    position: absolute;
    color: #bd1717;
    opacity: 1 !important;

    margin-top: 10px;
}

.settingsLockDiv {
    display: flex;
    opacity: 1 !important;

    justify-content: center;
}

.settingsLicensesButton {
    text-transform: none;
}