.homeDiscountPurchaseHeaderButton {
    font-size: x-large !important;
    margin-top: 6px !important;
}

.homeLockHeaderButton {
    font-size: large !important;
}

.homeMainGrid {
    max-width: 500px;

    display: flex;
    flex-direction: column;

}

.homeMenuDiv {
    display: grid;
    /* grid-template-columns: repeat(3, 1fr); */
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    column-gap: 10px;
    row-gap: 15px;

    padding: 10px;

    flex: 1;
}

@media only screen and (max-device-width: 480px) {
    .homeMenuDiv {
        grid-template-columns: repeat(2, 1fr);
    }
}

.homeMenuCard {
    border-radius: 10px;
    overflow: hidden;

    /* width: 30vw;
    height: 30vw;
    max-width: 200px;
    max-height: 200px; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 10px;
}

.homeMenuCardImg {
    cursor: pointer;
    object-fit: scale-down;
    margin-top: 15px;
}


.homeMenuCardTitle {
    font-size: large;
    font-weight: bold;

    text-align: center;

    margin-bottom: 0;
}

.homeMenuShareButton {
    margin-top: 50px;
    text-transform: none;
    font-weight: normal;
}

.homeNotificationsIllustration {
    width: calc(100vw / 3);
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
}


.modeSelectionPopover {
    --height: auto !important;
    --width: 50% !important;
}


.modeSelectionPopoverButtonCol {
    display: flex;
    justify-content: center;
}

.modeSelectionPopoverNumbersDiv {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, auto);
    margin-top: 10px;
    column-rule: 4px red;
}

.popover-viewport {
    display: contents;
}



.modeSelectionPopoverTitle {
    text-align: center;
    margin: 20px;
}

.modeSelectionPopoverMainDiv {
    display: grid;
}

.modeSelectionPopoverCol {
    margin: 20px;
    border: 1px solid #454545;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.modeSelectionPopoverButtonDiv {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.modeSelectionPopoverColContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
}

@media only screen and (max-width: 480px) {
    .modeSelectionPopover {
        --height: auto !important;
        --width: 80vw !important;
        display: flex;
        flex-direction: column;
    }

    .modeSelectionPopoverCol {
        margin: 10px;
        border: 1px solid #454545;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}


/* NOTIFICATIONS */
.homeNotificationsAlertGrid {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homeNotificationsAlertPlayerDiv {
    width: 20vw;
    max-width: 100px;
}

.homeNotificationsAlertCTAText {
    text-align: center;
}