.loginGrid {
    max-width: 500px;
}

.loginButtonRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loginSignupButtonRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loginGoToSignupButton {
    text-transform: none;
    flex: 1;
}

.loginSupportButton{
    flex: 1;
    text-transform: none;
    margin-top: 20px;
}