.matchingCardsMainContainer {
  height: 100%;

  display: flex;
  flex-direction: column;

  text-align: center;
}

.matchingCardsHeaderDiv {
  margin-top: 15px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.matchingCardsBackDiv {
  height: 50px;
  width: 50px;

  border-radius: 100px;
  overflow: hidden;

  background-color: var(--ion-color-light);
  padding: 10px;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.matchingCardsCallToActionParagraph {
  font-size: large;
}

.matchingCardsPointsDiv {
  height: 50px;
  width: 50px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--ion-color-warning);
  border: 1px solid var(--ion-color-warning);
  border-radius: 100px;
  overflow: hidden;

  padding: 5px;
}

.matchingCardsGameContainer {
  display: flex;
  flex-direction: row;
}

.matchingCardsMovableElementsDiv {
  flex: 1;

  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(3, auto);

  justify-content: center;
  align-items: center;
}

.matchingCardsMovableElement {
  width: 10vw;
  height: 10vw;

  display: flex;
  justify-content: center;

  border: 1px solid black;
  border-radius: 15px;

  margin: 15px;

  z-index: 1;
}

.matchingCardsTargetDiv {
  width: 11vw;
  height: 14vw;
  border-radius: 15px;
  overflow: hidden;
  background-color: lightcyan;

  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.matchingCardsTargetCol {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}


.matchingCardsAnswerImage {
  height: 10vw;
  width: 10vw;

  object-fit: scale-down;
  margin-right: -35%;
}

.matchingCardsTargetCardNameDiv {
  height: 40%;


  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 20px;
  overflow: hidden;

  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);

  margin-bottom: 20px;

  padding: 10px;
}

.matchingCardsTargetCardNameDiv2 {
  height: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 20px;
}

.matchingCardsTargetCardName {
  font-size: medium;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  animation: fadeIn linear .8s;
  -webkit-animation: fadeIn linear .8s;
  -moz-animation: fadeIn linear .8s;
  -o-animation: fadeIn linear .8s;
  -ms-animation: fadeIn linear .8s;

  color: black;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 480px) {
  .matchingCardsGameContainer {
    flex-direction: column-reverse;
    justify-content: space-around;
    height: 99%;
  }

  .matchingCardsAnswerImage {
    width: 100px;
    height: 100px;
  }

  .matchingCardsTargetCardName {
    font-size: x-small;
    margin: 0;
  }

  .matchingCardsHeaderDiv {
    margin-left: 5px;
    margin-right: 5px;
  }

  .matchingCardsMovableElementsDiv {
    grid-auto-flow: row;
    grid-template-columns: repeat(3, auto);
  }

  .matchingCardsMovableElement {
    width: 25vw;
    height: 25vw;
  }

  .matchingCardsTargetDiv {
    width: 25vw;
    height: 35vw;
  }
}

@media only screen and (max-width: 1024px) {

  .matchingCardsTargetCardName {
    font-size: 12px;
  }
}

@media (prefers-color-scheme: dark) {
  .matchingCardsMovableElement {
    background-color: #ffffff;
  }

  .matchingCardsMovableElement {
    border: 1px solid whitesmoke;
  }

  .matchingCardsPointsDiv {
    background-color: #F68A06;
    border: 1px solid #F68A06;
  }

  .matchingCardsTargetCardNameDiv {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
  }
}


/* * * * * * * */
/* CARD STYLE  */
/* * * * * * * */
@keyframes beat {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  100% {
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
}

.cardCardDiv {
  width: 100%;
  height: 100%;
}


.cardDraggableImage {
  width: 99%;
  height: 99%;
  object-fit: scale-down;
  cursor: grab;
}

/* UNMUTE BUTTON */
.matchingCardsUnmuteButtonDiv {
  position: absolute;
  top: 20px;
  right: 20px;

  height: 50px;
  width: 150px;
  border-radius: 10px;
  overflow: hidden;

  background-color: red;
  background: red;

  display: flex;
  flex-direction: row;
  align-items: center;

  animation-name: unmuteButtonAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  cursor: pointer;
}

.matchingCardsUnmuteButtonParagraph {
  flex: 3;
  font-weight: bold;
}

.matchingCardsUnmuteButtonIcon {
  flex: 1;
  font-size: x-large;

  animation: wiggle 1s infinite;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  80% {
    transform: rotate(0deg);
  }

  85% {
    transform: rotate(10deg);
  }

  95% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes unmuteButtonAnimation {
  0% {
    transform: scale(1.0);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1.0);
  }
}

@media screen and (orientation:landscape) and (max-height:450px) {

  .matchingCardsMovableElementsDiv {
    grid-template-rows: repeat(2, auto) !important;

  }
}