.supportGrid {
    display: flex;
    flex-direction: column;
    max-width: 500px;
}

.profileGridRow {
    flex: 1;
    justify-content: center;
    margin-bottom: 20px;
}

.profileSupportGrid {
    display: flex;
    flex-direction: column;
}

.supportRow {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.supportIcon {
    flex: 1;
}

.supportLabel {
    flex: 5
}