.signupGrid {
    max-width: 500px;
}

.signupGoToLoginButton {
    text-transform: none;
}

.signupTermsAndToggleRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.signupTermsParagraph {
    flex: 1;
    font-size: small;
}

.signupTermsToggle {
    padding-left: 20px;
}

.signupButtonRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.signupLoginLinkRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
}